import React from 'react'
import { useContext } from 'react'

import { Button } from '@roar/components'
import { setupLink } from '../common/utils'
import { Box } from 'rebass/styled-components'
import { graphql } from 'gatsby'
import { ModalContext } from './ModalWrapper'

const LinkWrapper = (props) => {
  const { links = [], colorScheme = 'black' } = props

  const { toggleModal } = useContext(ModalContext)
  return (
    <>
      {links &&
        links.map((link, idx) => {
          const { meta, external, to, destination, label, type = 'primary' } = link

          const linkObj = {
            type: type,
            __typename: type,
            slug: null,
            name: label,
            url: null,
            to,
            external,
            destination,
          }

          return (
            <Box key={`Box_${idx}`} {...setupLink(linkObj, toggleModal)}>
              <Button
                key={`Button_${idx}`}
                variant="primary"
                colorScheme={meta?.colorScheme ? meta.colorScheme : colorScheme}
                forwardedAs="div"
              >
                {label}
              </Button>
            </Box>
          )
        })}
    </>
  )
}

export const LINK_FIELDS = graphql`
  fragment LinkFields on ContentfulLinks {
    id
    contentful_id
    name
    to
    label
    external
    icon
    type
    meta {
      internal {
        content
      }
    }
    destination {
      __typename
      ... on ContentfulPage {
        contentful_id
        slug
      }
      ... on ContentfulPosts {
        contentful_id
        slug
        date
        year: date(formatString: "YYYY")
        month: date(formatString: "MM")
        postType {
          name
        }
        category {
          name
        }
      }
      ... on ContentfulDestinations {
        contentful_id
        slug
      }
      ... on ContentfulLandingPage {
        contentful_id
        slug
      }
      ... on ContentfulCharitablePartners {
        contentful_id
        slug
      }
    }
  }
`

export default LinkWrapper
