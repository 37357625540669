import React from 'react'
import { graphql } from 'gatsby'
import LinkWrapper from './LinkWrapper'
import { Card, Video, theme, Animated, slideUpAnimation, Heading } from '@roar/components'
import { mapper, setupLink } from '../common/utils'
import { Flex } from 'rebass/styled-components'
import RichTextRenderer from '../components/RichTextRenderer'
import { formatImage } from './ImageWrapper'
import styled from 'styled-components'

const CARD_TYPES = {
  OVERLAY: 'Overlay',
  PRIMARY_PORTRAIT: 'Primary portrait',
  PRIMARY_LANDSCAPE: 'Primary landscape',
  PERSON: 'Person',
  STANDARD: 'Standard',
  LEFTRIGHT: 'Left Right',
  VERTICAL_LARGE: 'Vertical Large',
  TRIP: 'Trip',
}

const VARIANT_MAP = {
  [CARD_TYPES.OVERLAY]: 'overlay',
  [CARD_TYPES.PRIMARY_PORTRAIT]: 'primaryPortrait',
  [CARD_TYPES.PRIMARY_LANDSCAPE]: 'primaryLandscape',
  [CARD_TYPES.PERSON]: 'person',
  [CARD_TYPES.STANDARD]: 'standard',
  [CARD_TYPES.LEFTRIGHT]: 'leftRight',
  [CARD_TYPES.VERTICAL_LARGE]: 'verticalLarge',
  [CARD_TYPES.TRIP]: 'trip',
}

const CARD_MAP = {
  variant: (value) => {
    const { variant } = value
    return VARIANT_MAP[variant] || 'standard'
  },
  hasLinkWrapper: (value) => {
    const { variant } = value
    return ![CARD_TYPES.OVERLAY].includes(variant)
  },
  subheading: ['subheading'],
  headline: ['headline'],
  focalPoint: (value) => {
    if (value.focalPoint) {
      return {
        x: value.focalPoint.focalPoint.x4,
        y: value.focalPoint.focalPoint.y4,
      }
    }
    return {
      x: 0,
      y: 0,
    }
  },
  image: (value) => formatImage(value, true),
  style: ['style'],
  video: (value) => {
    if (value.video) return value.video
    if (value.videoSource) return { src: value.videoSource, image: formatImage(value, true) }
    return null
  },
  description: (value) => {
    const { content } = value

    if (!content) {
      return null
    }

    return <RichTextRenderer content={content} />
  },
  alignment: ['style', 'alignment'],
}

const LINK_MAP = {
  links: ['links'],
  colorScheme: ['style', 'colorScheme'],
}

const ImageHover = styled(Flex)`
  overflow: hidden;
  figure:before {
    transition: transform ${theme.speed.slowest} ease !important;
  }

  :hover {
    figure:before {
      transform: scale(1.1);
    }
  }
`

const CardWrapper = (props) => {
  const cardProps = mapper(props, CARD_MAP)
  const linkProps = mapper(props, LINK_MAP)

  let link = {}

  if (linkProps.links && cardProps.hasLinkWrapper) {
    link = setupLink(linkProps.links[0])
  }

  if (cardProps.video) {
    return (
      <ImageHover className="Card-parent--hoverBorder" width="100%" {...cardProps.style}>
        <Animated shouldAnimate={true} variants={slideUpAnimation}>
          <Card
            meta={cardProps.subheading ? () => <Heading size="h7">{cardProps.subheading}</Heading> : null}
            {...cardProps}
            renderVisual={(imagePadding) => <Video {...cardProps.video} imagePadding={imagePadding} />}
            renderCta={() => <LinkWrapper {...linkProps} />}
          />
        </Animated>
      </ImageHover>
    )
  }

  return (
    <ImageHover className="Card-parent--hoverBorder" width="100%" {...cardProps.style} {...link}>
      <Animated shouldAnimate={true} variants={slideUpAnimation}>
        <Card
          {...cardProps}
          meta={cardProps.subheading ? () => <Heading size="h7">{cardProps.subheading}</Heading> : null}
          renderCta={() => <LinkWrapper {...linkProps} />}
        />
      </Animated>
    </ImageHover>
  )
}

export default CardWrapper

export const card = graphql`
  fragment Card on ContentfulCard {
    id
    name
    variant: type
    style {
      pt
      pr
      pl
      pb
      alignItems
      bg
      colorScheme
      alignment
    }
    subheading: subtitle
    headline
    focalPoint {
      focalPoint {
        x4: xp
        y4: yp
      }
    }
    image {
      gatsbyImageData(layout: CONSTRAINED, width: 1200, quality: 90)
    }
    videoSource
    links {
      ...LinkFields
    }
    video {
      ...Video
    }
    content {
      raw
      references {
        ... on ContentfulLinks {
          ...LinkFields
        }
      }
    }
  }
`
